import Route from '@ember/routing/route'
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AdminUsersEditDashboardConfigurationRoute extends Route {
  @service intl;
  @service router;
  @service session;

  model() {
    return this.modelFor('authenticated.admin.users.edit');
  }

  setupController(controller, model) {
    super.setupController(...arguments)

    controller.set('user', model);
  }

  @action
  willTransition(transition) {
    if (this.currentModel.get('hasDirtyAttributes')) {
      if (confirm(this.intl.t('authenticated.admin.users.edit.dashboard-configuration.actions.confirmMessage'))) {
        this.currentModel.rollbackAttributes();
        return true;
      } else {
        transition.abort();
      }
    }
  }
}
