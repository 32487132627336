import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedKasesManageIntegrationsRoute extends Route {
  layout = 'custom-aside';
  queryParams = {
    redirectPath: {
      refreshModel: true
    }
  };
  
  @service session;

  model() {
    return this.modelFor('authenticated.kases.manage');
  }

  async setupController(controller, model) {
    super.setupController(...arguments);

    controller.set('integrationServices', this.store.query('integrationService', {
      kase_id: model.id,
      not_category: ['accounting', 'answering_service'],
      per_page: 100,
      status: 1 // successful 
    }));
    controller.set('integrationExports', this.store.query('integrationExport', {
      kase_id: model.id
    }));
    controller.set('clientInfo', this.store.findRecord(
      'clientInfo',
      this.session.session.get('authenticated.client.client_info_id')
    ));

    const tributeWebsiteKaseIntegration = (await this.store.query('v2/kaseIntegration', {
      kase_id: model.get('id'),
      tribute_website_integration: true
    }))?.firstObject;
    controller.set('tributeWebsiteKaseIntegration', tributeWebsiteKaseIntegration);
  }
}
