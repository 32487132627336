import Model, { attr, hasMany, belongsTo } from '@ember-data/model';

export default class V2LocationModel extends Model {
  @attr('boolean') coaCollectTax;
  @attr('boolean') hasAftercareIntegration;
  @attr('boolean') hasBassMollettIntegration;
  @attr('boolean') hasCfsIntegration;
  @attr('boolean') hasCiclopsIntegration;
  @attr('boolean') hasTributePrintIntegration;
  @attr('boolean') hasForethoughtIntegration;
  @attr('boolean') hasFrazerIntegration;
  @attr('boolean') hasFuneraloneIntegration;
  @attr('boolean') hasMessengerIntegration;
  @attr('boolean') hasPrecoaIntegration;
  @attr('boolean') hasRemoteId;
  @attr('boolean') hasTransnationalBankIntegration;
  @attr('boolean') hasViewlogiesIntegration;
  @attr('boolean') kaseNumberingResetsMonthly;
  @attr('boolean') kaseNumberingResetsYearly;

  @attr('moment-datetime') archivedAt;
  @attr('moment-datetime') cfsExportedAt;
  @attr('moment-datetime') frazerExportedAt;
  @attr('moment-datetime') funeraloneExportedAt;
  @attr('moment-datetime') messengerExportedAt;

  @attr('number') stateSalesTaxRate;
  @attr('number') localSalesTaxRate;
  @attr('number') kaseNumberingStartingNumber;

  @attr('phone') faxNumber;
  @attr('phone') phoneNumber;

  @attr('string') clientId;
  @attr('string') coaIntegrationName;
  @attr('string') color;
  @attr('string') customId;
  @attr('string') defaultCremAuthHeaderId;
  @attr('string') defaultCremAuthHeaderUrl;
  @attr('string') defaultReceiptLogoId;
  @attr('string') defaultReceiptLogoUrl;
  @attr('string') defaultSfgsLogoId;
  @attr('string') defaultSfgsLogoUrl;
  @attr('string') defaultSoaLogoId;
  @attr('string') defaultSoaLogoUrl;
  @attr('string') email;
  @attr('string') generalPriceListId;
  @attr('string') kaseNumberingFormat;
  @attr('string') licenseNumber;
  @attr('string') name; // FIX: Breaks location creation
  @attr('string') netsuiteId;
  @attr('string') owner;
  @attr('string') remoteId;
  @attr('string') tributeWebsiteLocationId;
  @attr('string') websiteUrl;

  @belongsTo('v2/address') address;
  @belongsTo('v2/client') client;
  @belongsTo('v2/contractTemplate') defaultContractTemplate;
  @belongsTo('v2/logo', { async: true }) defaultReceiptLogo;
  @belongsTo('v2/logo', { async: true }) defaultSfgsLogo;
  @belongsTo('v2/logo', { async: true }) defaultSoaLogo;
  @belongsTo('v2/generalPriceList') generalPriceList;
  @belongsTo('v2/user') managingDirector;

  @hasMany('v2/general-payment') generalPayments;
  @hasMany('v2/organization', { async: true}) organizations;
  @hasMany('v2/salesTaxRates', { async: true }) salesTaxRates;
  @hasMany('v2/specialTaxes', { async: true }) specialTaxes;
  @hasMany('v2/vehicles', { async: true }) vehicles;
  @hasMany('v2/locationMetas', { async: true }) locationMetas;
}
