import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes>\n  <div class=\"input input--standard {{if @value \"input--has-value\"}}\">\n    <input\n        id={{this.inputId}}\n        class=\"input__field search__field\"\n        type=\"text\"\n        value={{@value}}\n        maxlength={{@maxlength}}\n        disabled={{@disabled}}\n        data-test={{@dataTest}}\n        {{on \"keyup\" this.change}}\n        {{on \"focusin\" (set this \"isFocused\" true)}}\n        {{on \"focusout\" (set this \"isFocused\" false)}}>\n\n    <label class=\"input__label\" for={{this.inputId}}>\n      {{#if (and @icon (not this.isFocused) (not @value))}}\n        <MdIcon @icon={{@icon}}/>\n      {{/if}}\n\n      {{#if @placeholder}}\n        {{@placeholder}}\n      {{else}}\n        Search\n      {{/if}}\n    </label>\n    <span class=\"input__border\"></span>\n  </div>\n</div>\n", {"contents":"<div ...attributes>\n  <div class=\"input input--standard {{if @value \"input--has-value\"}}\">\n    <input\n        id={{this.inputId}}\n        class=\"input__field search__field\"\n        type=\"text\"\n        value={{@value}}\n        maxlength={{@maxlength}}\n        disabled={{@disabled}}\n        data-test={{@dataTest}}\n        {{on \"keyup\" this.change}}\n        {{on \"focusin\" (set this \"isFocused\" true)}}\n        {{on \"focusout\" (set this \"isFocused\" false)}}>\n\n    <label class=\"input__label\" for={{this.inputId}}>\n      {{#if (and @icon (not this.isFocused) (not @value))}}\n        <MdIcon @icon={{@icon}}/>\n      {{/if}}\n\n      {{#if @placeholder}}\n        {{@placeholder}}\n      {{else}}\n        Search\n      {{/if}}\n    </label>\n    <span class=\"input__border\"></span>\n  </div>\n</div>\n","moduleName":"crakn/components/g/crakn/field/search.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/field/search.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';

/**
 * A one-way search text box to be utilized with the Search controller class.
 * 
 * ## Arguments
 * - **value** - the value used to query
 * - **onChange** - the function to call when the value has updated
 * - **placeholder** - *optional* the label for the search input
 * - **maxlength** - *optional* the maxmimum amount of characters allowed to be searched
 * - **disabled** - *optional* where or not the input should be disabled
 */
export default class GCraknFieldSearchComponent extends Component {
  inputId = 'searchInput-' + guidFor(this);

  @action
  change(event) {
    this.args.onChange(event.target.value);
  }
}