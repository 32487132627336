import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { sort } from '@ember/object/computed';
import {inject as service} from '@ember/service';

export default class AuthenticatedKasesManageIntegrationsIndexController extends Controller {
  @service tmsSettings

  @tracked tributeWebsiteKase; // Set up in the route.
  @tracked tributeVideoExport; // Set up in the route.
  @tracked tributeWebsiteKaseExported = null;

  get tributeVideoStatus() {
    const status = this.tributeVideoExport.get('tributeVideoStatus');

    return status || 'Please go to the integration page and update the status.';
  }

  @sort('integrationServices', function (a, b) {
    if (a.isTributeIntegration && !b.isTributeIntegration) {
      return -1;
    } else if (!a.isTributeIntegration && b.isTributeIntegration) {
      return 1;
    }

    return a.displayName.localeCompare(b.displayName);
  })
  sortedIntegrationServices;
}
