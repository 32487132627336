import Controller from '@ember/controller';
import { debounce } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';

export default class AdminUsersEditLocationAccessController extends Controller {
  @service store;

  @tracked organization_id = null;
  @tracked location_name = '';
  @tracked userId;

  queryParams = ['page', 'organization_id', 'location_name'];

  @computed('userId')
  get v2Organizations() {
    return this.store.query('v2/organization', { user_id: this.userId });
  }

  @action
  setOrganizationId(value) {
    this.organization_id = isNaN(value) ? null : value;
  }

  @action
  setLocationName(value) {
    debounce(this, this._setLocationName, value, 400);
  }

  _setLocationName(value) {
    this.location_name = value;
  }
}
