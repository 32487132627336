import Service, { inject as service } from '@ember/service';
import { sort } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';

export default class V2OrganizationsService extends Service {
  @service store;
  @service session;
  @tracked allOrganizations = null;

  organizationNameSort = ['name:asc'];
  @sort('all', 'organizationNameSort') allSorted;

  get all() {
    if (this.allOrganizations === null) {
      this.loadOrganizations();
    }

    return this.allOrganizations;
  }

  async loadOrganizations() {
    this.allOrganizations = await this.store.query('v2/organization', { archived: false, per_page: 1000 });
  }

  unloadAll() {
    this.allOrganizations = null;
  }
}
