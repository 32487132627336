import { readOnly, equal, reads } from '@ember/object/computed';
import Component from '@ember/component';
import { computed, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  'model.name': validator('presence', true),
  invoiceCategory: validator('presence', true),
  'model.pricingAmount': validator('number', {
    allowString: true,
    disabled: reads('model.isAddMode'),
    gt: 0,
    message: 'Must be greater than zero',
    positive: true
  }),
  'model.discountedTotal': validator('number', {
    gt: 0,
    disabled: reads('model.isAddMode')
  })
});

export default Component.extend(Validations, {
  classNames: ['add-edit-package'],
  flashes: service(),
  store: service(),

  model: null,
  showValidation: false,

  mode: 'edit',
  showDetails: true,

  amountPlaceholder: null,
  disablePricing: false,

  pricingMethod: readOnly('model.pricingMethod'),

  invoiceCategoryId: readOnly('model.invoiceCategoryId.id'),

  isNormal: equal('pricingMethod', 'normal'),

  isDiscount: equal('pricingMethod', function() {
    const pricingMethod = this.pricingMethod;

    return pricingMethod === 'amount';
  }),

  totalWarning: computed('model.{total,productsTotal}', function() {
    return get(this, 'model.total') > get(this, 'model.productsTotal');
  }),

  selectionPackageSlots: computed('model.id', function() {
    return this.store.query('v2/selection-package-slot', {
      selection_package_id: this.model.get('id'),
      per_page: 100,
    });
  }),

  actions: {
    addPackageProduct(product) {
      get(this, 'model.products').addObject(product);
      this.send('savePackage', false);
    },

    removePackageProduct(spp) {
      if (!spp) {
        return;
      }
      spp.destroyRecord().then(() => {
        this.flashes.addSuccess('Product removed from package');
        this.model.reload();
      }).catch(() => {
        this.flashes.addError('Something went wrong. Please try again.');
      });
    },

    savePackage(collapseEdit) {
      const selectionPackage = this.model;

      selectionPackage.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(this, 'showValidation', false);

          this
            .onSave(collapseEdit)
            .catch(() => {
              this.flashes.addError('Something went wrong. Please try again.');
            })
            .finally(() => {
              if (this.mode === 'add') {
                this.flashes.addSuccess(
                  'Package saved! Let\'s create another one.'
                );

                // Focus `name` field and clear category select
                this.element.querySelector('.qa-name').focus();
              } else {
                this.flashes.addSuccess('Package saved!');
              }
            });
        } else {
          set(this, 'showValidation', true);
        }
      });
    },

    cancelPackage() {
      this.onCancel(this.model);
    },

    revertItem(product) {
      product.rollbackAttributes();
    }
  }
});
