import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  accountNumberLastFour: validator('presence', {
    presence: true
  }),
  bankAccountName: validator('presence', {
    presence: true
  }),
  bankName: validator('presence', {
    presence: true
  }),
  generalLedgerAccountId: validator('presence', {
    presence: true
  }),
  routingNumberLastFour: validator('presence', {
    presence: true
  })
});

export default class AdminFinancialConfigurationsEditBankAccountsNewController extends Controller.extend(
  Validations
) {
    @service flashes;
    @service intl;
    @service router;

    @tracked accountNumberLastFour;
    @tracked bankAccountName;
    @tracked bankName;
    @tracked generalLedgerAccountId;
    @tracked generalLedgerAccountName;
    @tracked routingNumberLastFour;
    @tracked showValidations = false;

    @action
    changeGLAccount(GLAccountId) {
        set(this, 'generalLedgerAccountId', GLAccountId);
        const GLAccount = GLAccountId ? this.model.find(account => account.id === GLAccountId) : null;
        set(this, 'generalLedgerAccountName', GLAccount ? GLAccount.name : null);
      }
    
    @action
    save() {
        this.validate().then(({validations})=>{
          if (validations.get('isValid')){
            set(this, 'showValidations', false);   
            const bankAccount = this.store.createRecord('tribute-pay/v1/bank-account', {
                accountNumberLastFour: this.accountNumberLastFour,
                bankAccountName: this.bankAccountName,
                bankName: this.bankName,
                generalLedgerAccountId: this.generalLedgerAccountId,
                generalLedgerAccountName: this.generalLedgerAccountName,
                routingNumberLastFour: this.routingNumberLastFour,
              });
        
            bankAccount.save().then(()=>{
              this.flashes.addSuccess(this.intl.t('authenticated.admin.financial-configurations.edit.bank-accounts.new.messages.save.success'));
              this.router.transitionTo('authenticated.admin.financial-configurations.edit.bank-accounts.index')
            }).catch(()=>{
              this.flashes.addError(this.intl.t('authenticated.admin.financial-configurations.edit.bank-accounts.new.messages.save.error'));
            })
          }
          else{
            set(this, 'showValidations', true);
          }
        })     
    }
}
