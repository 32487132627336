import Model, { attr, hasMany } from '@ember-data/model';

export default class TributePayV1BankAccountModel extends Model {
    @attr('string') accountNumberLastFour;
    @attr('string') bankAccountName;
    @attr('string') bankName;
    @attr('string', { defaultValue: 90 }) dateRange;
    @attr('string') generalLedgerAccountId;
    @attr('string') generalLedgerAccountName;
    @attr('boolean', { defaultValue: false }) isCheckSettingEnabled;
    @attr('boolean', { defaultValue: false }) isDateRangeEnabled;
    @attr('string') routingNumberLastFour;
    @attr('string') startingCheckNumber;

    @hasMany('user') staffMembers;
}
