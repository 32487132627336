import { ActiveModelSerializer } from 'active-model-adapter';

export default class TributePayV1BankAccountSerializer extends ActiveModelSerializer {
    modelNameFromPayloadKey(key) {
        if(key == 'bank_account') {
            return 'tribute-pay/v1/bank-account';
        }

        return super.modelNameFromPayloadKey(...arguments);
      }

    payloadKeyFromModelName(modelName) {
        if (modelName === 'tribute-pay/v1/bank-account') {
          return 'bank_account'; 
        }
        
        return super.payloadKeyFromModelName(...arguments);
      }

    //This will be removed once the backend API for check settings is implemented
    normalizeResponse(store, modelClass, payload, id, requestType) {
      if (requestType === 'findRecord' && payload.bank_account) {
        const bankAccount = payload.bank_account;
        
        bankAccount.starting_check_number ??= '';
        bankAccount.is_date_range_enabled ??= false;
        bankAccount.is_check_setting_enabled ??= false;
        bankAccount.date_range ??= 90;
    }

      return super.normalizeResponse(store, modelClass, payload, id, requestType);
    }
}
