import SearchRoute from 'crakn/routes/search';
import { inject as service } from '@ember/service';

export default class AdminUsersEditLocationAccessRoute extends SearchRoute {
  @service router;
  @service session;
  @service store;
  @service userConfig;

  queryParams = {
    page: {
      refreshModel: true
    },
    organization_id: {
      refreshModel: true
    },
    location_name: {
      refreshModel: true
    }
  }

  model(params) {
    const userId = this.modelFor('authenticated.admin.users.edit').get('id');
    return this.store.query('v2/userLocation', {
      user_id: userId,
      archived: false,
      ...params
    });
  }

  modelType = 'v2/userLocation';
  routeRegex = /authenticated.admin.users.edit.location-access/;

  setupController(controller) {
    super.setupController(...arguments);
    controller.userId = this.modelFor('authenticated.admin.users.edit').get('id');
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('organization_id', null);
    }
  }
}
