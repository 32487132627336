import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<G::Crakn::Field::Select\n    @dataTest={{@dataTest}}\n    @placeholder={{this.placeholder}}\n    @placeholderLabel={{this.placeholderLabel}}\n    @placeholderValue={{@placeholderValue}}\n    @value={{@value}}\n    @disabled={{@disabled}}\n    @showValidations={{@showValidations}}\n    @validationMessages={{@validationMessages}}\n    @onChange={{@onChange}}\n    ...attributes>\n  {{#each this.v2Organizations.allSorted as |organization|}}\n    <option\n        value={{organization.id}}\n        selected={{eq @value organization.id}}>\n      {{organization.name}}\n    </option>\n  {{/each}}\n</G::Crakn::Field::Select>\n", {"contents":"<G::Crakn::Field::Select\n    @dataTest={{@dataTest}}\n    @placeholder={{this.placeholder}}\n    @placeholderLabel={{this.placeholderLabel}}\n    @placeholderValue={{@placeholderValue}}\n    @value={{@value}}\n    @disabled={{@disabled}}\n    @showValidations={{@showValidations}}\n    @validationMessages={{@validationMessages}}\n    @onChange={{@onChange}}\n    ...attributes>\n  {{#each this.v2Organizations.allSorted as |organization|}}\n    <option\n        value={{organization.id}}\n        selected={{eq @value organization.id}}>\n      {{organization.name}}\n    </option>\n  {{/each}}\n</G::Crakn::Field::Select>\n","moduleName":"crakn/components/g/crakn/field/select/organization.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/field/select/organization.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class GCraknFieldSelectOrganizationComponent extends Component {
  @service intl;
  @service v2Organizations;

  get placeholder() {
    return this.args.placeholder ?? this.intl.t('components.g.crakn.field.select.organization.selectField.placeholder');
  }

  get placeholderLabel() {
    return this.args.placeholderLabel ?? this.intl.t('components.g.crakn.field.select.organization.selectField.options.all');
  }
}
