import Controller from '@ember/controller';
import { set } from '@ember/object';
import Search from 'crakn/mixins/search';

export default Controller.extend(Search, {
  queryParams: ['sort_by', 'order'],

  sort_by: null,
  order: 'ASC',
  addMode: false,

  menuPlacement: ['top', 'bottom'],

  actions: {
    toggleAddMode() {
      if (this.toggleProperty('addMode')) {
        set(this, 'query', null);
      }
    },

    sortBy(sortProperty) {
      if (this.sort_by === sortProperty) {
        set(this, 'order', this.order === 'DESC' ? 'ASC' : 'DESC');
      } else {
        set(this, 'sort_by', sortProperty);
        set(this, 'order', 'ASC');
      }
    }
  }
});
