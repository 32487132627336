import { helper as buildHelper } from '@ember/component/helper';

export function kebabCase(value) {
  return value[0]
    .replace(/[^\w\s/]+/g, '') // remove all non-word chars
    .replace(/[/\s]+/g, '-') // replace slashes and spaces with '-'
    .toLowerCase();
}

export default buildHelper(kebabCase);
