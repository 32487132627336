import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AdminUsersEditPermissionsController extends Controller {
  @service api;
  @service intl;
  @service userConfig;
  @service flashes;
  @service session;

  get canEditPermissionSlider() {
    if (
      this.model.get('isSuperuser') &&
      !this.session.currentUser.get('isSuperuser')
    ) {
      return false;
    } else {
      return true;
    }
  }

  get maximumPermission() {
    if (
      this.session.currentUser.get('permissions') >= 4 &&
      this.session.currentUser.get('isSuperuser')
    ) {
      return 5;
    } else if (this.session.currentUser.get('permissions') >= 4) {
      return 4;
    } else if (this.session.currentUser.get('permissions') === 3) {
      return 2;
    } else {
      return 0;
    }
  }

  get canPostContractLabel() {
    return this.session.currentClient.relabel_contract_to_invoice
      ? 'Can Post Invoice/Add-on?'
      : 'Can Post Contract/Add-on?';
  }

  get canUnpostContractLabel() {
    return this.session.currentClient.relabel_contract_to_invoice
      ? 'Can Unpost Invoice/Add-on?'
      : 'Can Unpost Contract/Add-on?';
  }

  handleSaveSuccess() {
    this.flashes.addSuccess(
      this.intl.t(
        'authenticated.admin.users.edit.permissions.actions.savingUser.successMessage'
      )
    );
  }

  handleSaveError() {
    this.flashes.addError(
      this.intl.t(
        'authenticated.admin.users.edit.permissions.actions.savingUser.errorMessage'
      )
    );
  }

  @action
  save() {
    if (this.user.hasDirtyAttributes) {
      this.user
        .save()
        .then(() => this.handleSaveSuccess())
        .catch(() => this.handleSaveError());
    }
  }
}
