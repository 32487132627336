import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class CraknClientsEditCoaConfigController extends Controller {
  @controller('authenticated/crakn/clients/edit') edit;

  @tracked isEditing = false;
  @tracked soaTemplates = [this.client];

  customFontSizes = [8, 9, 10, 11];

  @computed('client.soaCustomFontSize')
  get useCustomFont() {
    const customFontSize = this.client.soaCustomFontSize;

    return customFontSize !== null && customFontSize !== undefined;
  }

  @action
  cancelSoaTemplate(template) {
    this.isEditing = false;
    template.rollbackAttributes();
  }

  @action
  save(event) {
    this.edit.save(event);
    this.isEditing = false;
  }
}
