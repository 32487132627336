import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<section class={{@class}}>\n  {{! template-lint-disable attribute-indentation }}\n  {{yield (\n    hash\n    Button=(component \"g/crakn/menu/button\"\n          buttonID=this.buttonID\n          class=@class\n          onClick=this.toggleMenu\n        )\n    Icon-Button=(component \"g/crakn/menu/icon-button\"\n                  buttonIconID=this.buttonIconID\n                  buttonID=this.buttonID\n                  class=@class\n                  data-test=@dataTest\n                  onClick=this.toggleMenu\n                )\n    Popup=(component \"g/crakn/menu/popup\"\n            closeMenu=this.hideMenu\n            minWidth=@minWidth\n            popupID=this.popupID\n            setup=this.setup\n          )\n  )}}\n</section>\n", {"contents":"<section class={{@class}}>\n  {{! template-lint-disable attribute-indentation }}\n  {{yield (\n    hash\n    Button=(component \"g/crakn/menu/button\"\n          buttonID=this.buttonID\n          class=@class\n          onClick=this.toggleMenu\n        )\n    Icon-Button=(component \"g/crakn/menu/icon-button\"\n                  buttonIconID=this.buttonIconID\n                  buttonID=this.buttonID\n                  class=@class\n                  data-test=@dataTest\n                  onClick=this.toggleMenu\n                )\n    Popup=(component \"g/crakn/menu/popup\"\n            closeMenu=this.hideMenu\n            minWidth=@minWidth\n            popupID=this.popupID\n            setup=this.setup\n          )\n  )}}\n</section>\n","moduleName":"crakn/components/g/crakn/menu/wrapper.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/menu/wrapper.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { createPopper } from '@popperjs/core';
import { guidFor } from '@ember/object/internals';

export default class GCraknMenuWrapper extends Component {
  button = null;
  popper = null;
  popup = null;
  defaultPlacement = ['right', 'left', 'top', 'bottom'];
  flip = {
    name: 'flip',
    options: {
      allowedAutoPlacements: this.args.placement ? this.args.placement : this.defaultPlacement,
      rootBoundary: 'viewport'
    }
  };
  listenToOutsideClicks = this.outsideClickListener.bind(this);
  offset = { 
    name: 'offset',
    options: {
      offset: this.args.offset ? this.args.offset : [0, 8]
    }
  };
  placement = 'auto';
  preventOverflow = { name: 'preventOverflow', options: { padding: 10 } };

  

  get buttonID() {
    return this.args.id || 'popper-base-' + guidFor(this);
  }

  get buttonIconID() {
    return this.args.id || 'popper-base-Icon-' + guidFor(this);
  }

  get popupID() {
    return this.args.popupID || 'popper-' + guidFor(this);
  }

  get isOpen() {
    return this.popup.hasAttribute('show-popper');
  }

  createPopperInstance(button, popup) {
    this.popper = createPopper(button, popup, {
      placement: this.placement,
      modifiers: [
        this.offset,
        this.flip,
        this.preventOverflow
      ]
    });
  }

  destroyPopperInstance() {
    if (!this.popper) {
      return;
    }
    this.popper.destroy();
    this.popper = null;
  }

  outsideClickListener(e) {
    const isMenuButton = e.target.id == this.buttonID || e.target.id == this.buttonIconID;
    const clickInsideMenu = this.popup.contains(e.target);
    if (clickInsideMenu || isMenuButton) {
      return;
    }
    this.hideMenu();
  }

  @action
  showMenu() {
    this.popup.setAttribute('show-popper', '');
    this.createPopperInstance(this.button, this.popup);
    document.body.addEventListener('click', this.listenToOutsideClicks, true);
  }

  @action
  hideMenu() {
    this.popup.removeAttribute('show-popper');
    this.destroyPopperInstance();
    document.body.removeEventListener('click', this.listenToOutsideClicks, true);
  }

  @action
  setup() {
    this.button = document.querySelector(`#${this.buttonID}`);
    this.popup = document.querySelector(`#${this.popupID}`);
  }

  @action
  toggleMenu() {
    this.isOpen ? this.hideMenu() : this.showMenu();
  }

}
