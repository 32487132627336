import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { filterBy, equal } from '@ember/object/computed';
import { get, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import CustomAction from 'crakn/mixins/custom-action';
import { validStatusTypes } from 'crakn/utils/status-helpers';
import { sortBy } from 'lodash-es/collection';
import { sortKaseContact } from 'crakn/utils/kase-contact-sorter';

export default Model.extend(CustomAction, {
  modelName: 'kases',

  casketingAndCosmetics: attr('boolean'),
  coronaVirusWarning: attr('boolean'),
  declineEmbalming: attr('boolean'),
  export: attr('boolean'),
  generalPriceListLocked: attr('boolean'),
  hadAutopsy: attr('boolean'),
  hasChainOfCustody: attr('boolean'),
  hasImplantedDevices: attr('boolean'),
  hasKaseCauseOfDeath: attr('boolean'),
  hasKaseForms: attr('boolean'),
  hasNoEvents: attr('boolean'),
  hasPermissionToEmbalm: attr('boolean'),
  hideDeathNotice: attr('boolean'),
  tagsLocked: attr('boolean'),
  includeInVirginiaVitalStatsReport: attr('boolean'),
  isAlcoholRelated: attr('boolean'),
  isAutopsyAfterDonation: attr('boolean'),
  isBodyDonation: attr('boolean'),
  isCoronerNotified: attr('boolean'),
  isCoronersKase: attr('boolean'),
  isExaminerInvestigatorCase: attr('boolean'),
  isFindingsAvailable: attr('boolean'),
  isHiddenFromWhiteboard: attr('boolean'),
  isInjuryRelated: attr('boolean'),
  isOrganDonor: attr('boolean'),
  isPregnant: attr('boolean'),
  isTobaccoRelated: attr('boolean'),
  isVeteran: attr('boolean'),
  isWorkInjury: attr('boolean'),
  jcgReportSendSurveyOptOut: attr('boolean'),
  locationLocked: attr('boolean'),
  permissionToFingerprint: attr('boolean'),
  permissionToPhotograph: attr('boolean'),
  permissionToTransfer: attr('boolean'),
  positivelyIdentified: attr('boolean'),
  releasedForPickup: attr('boolean'),
  shipIn: attr('boolean'),
  tradeCall: attr('boolean'),
  transportToCrematory: attr('boolean'),

  asdExportedAt: attr('moment-datetime'),
  createdAt: attr('moment-datetime'),
  crematoryTransportTime: attr('moment-datetime', { defaultValue: null }),
  dateCasketDelivered: attr('moment-date'),
  dateCasketOrdered: attr('moment-date'),
  embalmAuthorizationDate: attr('moment-datetime'),
  embalmingDate: attr('moment-datetime'),
  exportedAt: attr('moment-datetime'),
  hebrewDeathDate: attr('moment-datetime'),
  injuryDateTime: attr('moment-datetime'),
  identificationDate: attr('moment-datetime'),
  marriageDate: attr('moment-datetime'),
  removalPickupTime: attr('moment-datetime'),
  shipInDateTimePickup: attr('moment-datetime'),
  shipoutDateTimePickup: attr('moment-datetime'),
  updatedAt: attr('moment-datetime'),

  avatar: attr('object'),

  pickupPhone: attr('phone'),

  bandName: attr('string'),
  bodyDonationNotes: attr('string'),
  casket: attr('string'),
  casketingAndCosmeticsNotes: attr('string'),
  chainOfCustodyQrCodeUrl: attr('string'),
  citizenship: attr('string'),
  clothing: attr('string'),
  customId: attr('string'),
  deathCertificateNumber: attr('string'),
  deceasedTagNumber: attr('string'),
  eiaCaseNumber: attr('string'),
  eiaCaseWorker: attr('string'),
  eyeColor: attr('string'),
  fingerprintReason: attr('string'),
  fingerprintReasonCustom: attr('string'),
  glasses: attr('string'),
  hairColor: attr('string'),
  hairstyle: attr('string'),
  height: attr('string'),
  identificationMark: attr('string'),
  identificationMethod: attr('string'),
  identificationNature: attr('string'),
  identificationNatureCustom: attr('string'),
  informantRelationship: attr('string'),
  injuryDescription: attr('string'),
  injuryLocation: attr('string'),
  injuryPlace: attr('string'),
  investigationNumber: attr('string'),
  jewelry: attr('string'),
  lastKnownLocation: attr('string'),
  lipstick: attr('string'),
  nailColor: attr('string'),
  nextAvailableStatus: attr('string'),
  organDonationType: attr('string'),
  pacemakerDetail: attr('string'),
  permitTransitNumber: attr('string'),
  photographyReason: attr('string'),
  photographyReasonCustom: attr('string'),
  photographySubject: attr('string'),
  photographySubjectCustom: attr('string'),
  pickupInstructions: attr('string'),
  placeOfDeath: attr('string'),
  podAddressCity: attr('string'),
  podAddressLine1: attr('string'),
  podAddressLine2: attr('string'),
  podAddressState: attr('string'),
  podAddressZip: attr('string'),
  preneedCaseId: attr('string'),
  preneedSfgsUuid: attr('string'),
  reasonForNoEvents: attr('string'),
  reasonToEmbalm: attr('string'),
  reserveName: attr('string'),
  servicesInterestedIn: attr('string'),
  shipInFlightInformation: attr('string'),
  shipInOrganizationName: attr('string'),
  shipInTransportation: attr('string'),
  shipoutFlightInformation: attr('string'),
  shipoutTransportation: attr('string'),
  specialCircumstances: attr('string'),
  personGovernmentIdentifier: attr('string'),
  stateFileId: attr('string'),
  status: attr('string'),
  tradeCallFinancialArrangements: attr('string'),
  tradeCallNotes: attr('string'),
  tradeCallOrganizationName: attr('string'),
  tradeCallTransportNotes: attr('string'),
  tradeCallType: attr('string'),
  treatyNumber: attr('string'),
  tributeVideoUrl: attr('string'),
  uuid: attr('string'),
  weight: attr('string'),

  bodyDonationContact: belongsTo('person', { async: true }),
  crematedRemainsMailRecipient: belongsTo('person'),
  deathCertifier: belongsTo('person', { async: true }),
  doctor: belongsTo('person', { async: true }),
  father: belongsTo('person', { async: true }),
  hairdresser: belongsTo('person', { async: true }),
  informant: belongsTo('person', { async: true }),
  mother: belongsTo('person', { async: true }),
  personalPropertyRecipient: belongsTo('person'),
  removedImplantsRecipient: belongsTo('person'),
  shipInContact: belongsTo('person', { async: true }),
  shipInPickup: belongsTo('person', { async: true }),
  spouse: belongsTo('person', { async: true }),
  tradeCallContact: belongsTo('person', { async: true }),
  tradeCallPickup: belongsTo('person', { async: true }),

  bodyDonationPlace: belongsTo('place', { async: false }), // The transportation page uses the crakn-places-selector typeahead. Typeaheads don't like async: true.
  embalmingPlace: belongsTo('place', { async: false }),
  identificationPlace: belongsTo('place', { async: false }),
  marriagePlace: belongsTo('place', { async: false }),
  place: belongsTo('place', { async: false }), // The disposition page uses the crakn-places-selector typeahead. Typeaheads don't like async: true.
  tradeCallPlace: belongsTo('place', { async: false }),
  vendor: belongsTo('place', { async: false }),

  bodyDonationStaffMember: belongsTo('user', { async: true }),
  casketingAndCosmeticsStaff: belongsTo('user', { async: true }),
  createdByUser: belongsTo('user', { async: true }),
  crematoryTransportStaff: belongsTo('user', { async: true }),
  embalmer: belongsTo('user', { async: true }),
  preneedStaffMember: belongsTo('user', { async: true }),
  receivedByStaffMember: belongsTo('user', { async: true }),
  removalStaffMember: belongsTo('user', { async: true }),
  secondaryStaffMember: belongsTo('user', { async: true }),
  staffMember: belongsTo('user', { async: true }),

  nextOfKin: belongsTo('kaseContact', { async: true }),
  primaryContact: belongsTo('kaseContact', { async: true }),

  deceased: belongsTo('deceased', { async: true }),
  deceasedIdentifier: belongsTo('kasePerson', { async: true }),
  disposition: belongsTo('v2/disposition'),
  kaseFinancial: belongsTo('kaseFinancial', { async: true }),
  location: belongsTo('location', { async: true }),
  preneed: belongsTo('preneed', { async: true }),
  serviceInfo: belongsTo('serviceInfo', { async: true }),
  transportToLocation: belongsTo('location', { async: true }),

  casketingAndCosmeticsSecondaryStaff: hasMany('user', { async: true }),
  embalmingSecondaryStaff: hasMany('user', { async: true }),
  events: hasMany('event', { async: true }),
  kaseContacts: hasMany('kaseContact', { async: true }),
  kasePeople: hasMany('kasePerson', { async: true }),
  kaseTags: hasMany('kase-tag', { async: true }),
  kaseTasks: hasMany('kaseTask', { async: true }),
  notes: hasMany('note', { async: true }),
  removalSecondaryStaffMember: hasMany('user', { async: true }),

  clergy: filterBy('kasePeople', 'role', 'clergy'),

  excludeDeceased: computed('status', 'deceased', function() {
    const status = this.status;
    if (status != 'preneed') {
      return get(this, 'deceased.id');
    } else {
      return null;
    }
  }),

  podAddress: computed(
    'podAddressLine1',
    'podAddressLine2',
    'podAddressCity',
    'podAddressState',
    'podAddressZip',
    function() {
      return [
        this.podAddressLine1,
        this.podAddressLine2,
        this.podAddressCity,
        this.podAddressState,
        this.podAddressZip
      ]
        .filter(Boolean)
        .join(', ');
    }
  ),

  sortedKaseContacts: computed(
    'kaseContacts.[]',
    'primaryContact',
    'nextOfKin',
    {
      get() {
        return sortKaseContact(this.kaseContacts);
      }
    }
  ),

  sortedEvents: computed('events.[]', {
    get() {
      return sortBy(this.events.toArray(), event => {
        return event.get('startsAt') ? event.get('startsAt').unix() : null;
      });
    }
  }),

  authorizedForPickup: filterBy('kasePeople', 'role', 'authorized_for_pickup'),

  secondaryClergies: filterBy('kasePeople', 'role', 'secondary_clergy'),

  pallbearers: filterBy('kasePeople', 'role', 'pallbearer'),

  honoraryPallbearers: filterBy('kasePeople', 'role', 'honorary_pallbearer'),

  viewableStatus: computed('status', {
    get() {
      return validStatusTypes.find(t => t.id === this.status).name;
    }
  }),

  isPreneed: equal('status', 'preneed'),

  // Determines if preneed information should be shown on Info
  // If preneedCase Id exists, this information should be shown
  // regardless if the kase status is preneed
  displayPreneedValues: computed(
    'preneedCaseId',
    'isPreneed',
    'preneed.{hasTrust,hasInsurance}',
    function() {
      const hasPreneedRelationshipOrStatus = () => !isEmpty(this.preneedCaseId) || this.isPreneed;
      const preneedHasTrustOrInsurance = () => this.get('preneed.hasTrust') || this.get('preneed.hasInsurance');

      if (hasPreneedRelationshipOrStatus() || preneedHasTrustOrInsurance()) {
        return true;
      } else {
        return false;
      }
    }
  ),

  markStatus(status) {
    const type = this.constructor.modelName;
    return this.customAction('mark_status', 'POST', { status }).then(result => {
      this.store.pushPayload(type, result);
    });
  },

  notify(userId) {
    return this.customAction('notify', 'POST', { userId });
  },

  people(params) {
    return this.store.adapterFor('kases').people(this, params);
  },

  identify() {
    const adapter = this.store.adapterFor('kases');
    return adapter.identify(this);
  },

  unlock() {
    return this.customAction('unlock', 'POST');
  },

  hasPostedSelections() {
    const selections = this.kaseFinancial.get('selections');

    if (selections) {
      return selections.any((selection) => selection.posted);
    } else {
      return false;
    }
  }
});
