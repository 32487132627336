import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedCraknFormKeysIndexRoute extends Route {
  @service session;
  @service api;

  queryParams = {
    kase_id: {
      refreshModel: true,
    },
  };

  async model(params) {
    const kaseId = params.kase_id;

    if (!kaseId) {
      return null;
    }

    const response = await this.api.json.get(`v2/kases/${kaseId}/form_keys`);

    if (response.ok) {
      const kase = await this.store.findRecord('v2/kase', kaseId, { include: 'deceased'})
      return { kase: kase, formKeys: response.parsedJson };
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    if (model && model.kase) {
      controller.send('selectKase', model.kase);
      controller.loadAllKeys();
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('kase_id', null);
      controller.set('filterFormKeyQuery', null);
      controller.set('selectedKase', null);
      controller.set('filteredFormKeys', null);
    }
  }
}
