import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AuthenticatedCraknFormKeysIndexController extends Controller {
  @tracked selectedKase = null;
  @tracked filteredFormKeys = null;

  queryParams = ['kase_id'];
  kase_id = null;
  filterFormKeyQuery = null;

  @action
  selectKase(kase) {
    if (kase === undefined) {
      return;
    }
    set(this, 'selectedKase', kase);
    set(this, 'kase_id', kase.id);
    set(this, 'filterFormKeyQuery', null);
  }

  @action
  clearKase() {
    set(this, 'selectedKase', null);
    set(this, 'kase_id', null);
    set(this, 'filteredFormKeys', null);
    set(this, 'filterFormKeyQuery', null);
  }

  @action
  async filterFormKeys(filter) {
    set(this, 'filterFormKeyQuery', filter);

    if (!filter) {
      this.filteredFormKeys = this.model?.formKeys || {};
      return;
    }

    if (!this.model?.formKeys) {
      return;
    }

    const lowercaseFilter = filter.toLowerCase();

    this.filteredFormKeys = Object.entries(this.model.formKeys).filter(([key, value]) => {
      return (
        key.toLowerCase().includes(lowercaseFilter) ||
        value.toLowerCase().includes(lowercaseFilter)
      );
    }).reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
  }

  @action
  async downloadJson() {
    const jsonString = JSON.stringify(this.model.formKeys, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'form_keys.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  loadAllKeys() {
    this.filteredFormKeys = this.model?.formKeys || {};
  }
}
